<template>
  <div>
    <b-modal
      id="dienteModal"
      ok-only
      size="xl"
      class="p-5"
      title="Atención diente"
    >
      <b-card>
        <b-row>
          <b-col cols="6">
            <div class="d-flex">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h5 class="mb-0 ml-50">
                Paciente : <span class="text-primary">{{ paciente }}</span>
              </h5>
              <!-- b-badge variant="success">
                DIENTE: 18
              </b-badge> -->
            </div>
            <div class="d-flex my-1">
              <feather-icon
                icon="MehIcon"
                size="19"
              />
              <h5 class="mb-0 ml-50">
                Diente : <span>{{ nombrediente }}</span>
              </h5>
            </div>
            <div class="d-flex mt-1">
              <b-button
                size="sm"
                variant="outline-success"
                @click="nuevaAtencion"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="15"
                />
                Adicionar tratamiento
              </b-button>
            </div>
          </b-col>
          <b-col cols="2">
            <div
              class="border rounded p-1 m-0 text-center"
              height="90px"
              width="50px"
            >
              <img
                class="px-1 pt-1 rounded"
                :src="require(`@/assets/images/odontologia/${dataDienteSelect.presencialidad == 'presente' ? dataDienteSelect.diente.url_presente : dataDienteSelect.diente.url_ausente}`)"
                alt=""
                height="90px"
                :class="dataDienteSelect.array_estados"
              >
            </div>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Estados:"
            >
              <b-form-checkbox
                v-for="(opt,index) in optionsEstados"
                :key="index"
                v-model="selectedEstados"
                :value="opt.value"
                name="flavour-4a"
                class="custom-control-default"
                @change="actualizarEstados(opt.value)"
              >
                {{ opt.text }} <span style="display:inline-block;">
                  <div
                    class="mx-2"
                    style="width:40px; border-radius:5px; height:7px;"
                    :style="{background : opt.color}"
                  />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- nueva atencion del diente -->
      <b-card
        v-if="shownNuevaatencion"
        class="border-info"
        :class="[color_create_update]"
      >
        <b-row>
          <b-col md="6">
            <div class="d-flex">
              <feather-icon
                icon="CheckSquareIcon"
                size="19"
                class="text-primary"
              />
              <h5 class="mb-1 ml-50 text-primary">
                <strong>{{ texto_create_update }}</strong>
              </h5>
            </div>
          </b-col>
          <b-col md="6">
            <span
              class="btn btn-outline-danger btn-sm float-right"
              @click="shownNuevaatencion = false"
            >
              <feather-icon
                icon="XIcon"
                size="14"
              />
            </span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label>Diagnostico CIE 10</label>
            <vue-autosuggest
              ref="autocomplete"
              v-model="queryDiagnos"
              :suggestions="suggestionsDiagnos"
              :input-props="inputPropsDiagnos"
              :section-configs="sectionConfigsDiagnos"
              :render-suggestion="renderSuggestionDiagnos"
              :get-suggestion-value="getSuggestionValueDiagnos"
              @input="fetchResultsDiagnos"
            />
            <b-table
              head-variant="dark"
              class="mt-1"
              :items="diagnosticos"
              :fields="fieldsDiagnosticos"
              :busy.sync="isBusy"
              size="sm"
              sticky-header="250px"
              small
              :empty-text="'No hay registros para mostrar'"
            >
              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  @click="quitarDiagnostico(row.item)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="12"
                  />
                </b-button>
              </template>
            </b-table>
          </b-col>
          <b-col cols="6">
            <label>Procedimientos</label>
            <vue-autosuggest
              ref="autocomplete2"
              v-model="queryProcedim"
              :suggestions="suggestionsProcedim"
              :input-props="inputPropsProcedim"
              :section-configs="sectionConfigsProcedim"
              :render-suggestion="renderSuggestionProcedim"
              :get-suggestion-value="getSuggestionValueProcedim"
              @input="fetchResultsProcedim"
            />

            <b-table
              head-variant="dark"
              class="mt-1"
              :items="procedimientos"
              :fields="fieldsProcedimientos"
              :busy.sync="isBusy"
              size="sm"
              sticky-header="250px"
              small
              :empty-text="'No hay registros para mostrar'"
            >
              <template #cell(actions)="row">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  @click="quitarProcedimiento(row.item)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    size="12"
                  />
                </b-button>
              </template>
            </b-table>
          </b-col>
          <b-col md="12">
            <label for="">
              Observaciones:
            </label>
            <b-form-textarea v-model="observaciones" />
          </b-col>
          <b-col cols="12">
            <b-button
              size="sm"
              variant="gradient-primary"
              class="btn btn-block mt-1"
              @click="clickbtnAtencionDiente"
            >
              <feather-icon
                icon="SaveIcon"
                size="15"
              /> {{ texto_boton_create_update }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col md="12">
            <div class="d-flex">
              <feather-icon
                icon="ListIcon"
                size="19"
                class="text-primary"
              />
              <h5 class="mb-1 ml-50 text-primary">
                <strong>Historia Dental</strong>
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-table
          :items="atencionesDiente"
          :fields="fieldHistorial"
          :busy.sync="isBusy"
          size="sm"
          sticky-header="350px"
          small
          head-variant="dark"
          :empty-text="'No hay registros para mostrar'"
        >
          <template #cell(show_diagnosticos_procedimientos)="row">
            <span
              class="btn text-center"
            >
              <feather-icon
                :icon="row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                size="19"
                @click="row.toggleDetails"
              />
            </span>

          </template>

          <template
            #row-details="row"
            class="m-0 p-0"
          >
            <b-card class="m-0 p-0">
              <b-row>
                <b-col
                  cols="6"
                  class="text-sm-center"
                >
                  <b-card
                    no-body
                    class="border"
                  >
                    <b-card-header class="bg-light-danger">
                      <strong>Diagnosticos</strong>
                    </b-card-header>
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(item,index) in row.item.diagnosticos"
                        :key="index"
                        href="#"
                        class="text-left"
                      >
                        <span class="text-danger">
                          {{ item.codigo }}
                        </span> {{ item.descripcion }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-col>
                <b-col
                  cols="6"
                  class="text-sm-center"
                >
                  <b-card
                    no-body
                    class="border"
                  >
                    <b-card-header class="bg-light-success">
                      <strong>Procedimientos</strong>
                    </b-card-header>
                    <b-list-group flush>
                      <b-list-group-item
                        v-for="(item,index) in row.item.procedimientos"
                        :key="index"
                        href="#"
                        class="text-left"
                      >
                        <span class="text-success">
                          {{ item.codigo_procedimiento }}
                        </span> {{ item.denominacion_procedimiento }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="editarAtencionDiente(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">editar</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteAtencionDiente(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">eliminar</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>
      </b-card>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="gradient-primary"
            size="sm"
            class="float-right"
            @click="$bvModal.hide('dienteModal')"
          >
            Cerrar <feather-icon
              icon="Minimize2Icon"
              size="15"
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BTable, BRow, BCol, BCard, BDropdownItem, BDropdown, BFormTextarea, BFormGroup, BFormCheckbox,
  BListGroup, BListGroupItem, BCardHeader,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import storeDienteAtencion from '@/views/salud/odontologia/odontograma/diente-atencion/dienteAtencionStoreModule'

export default {
  components: {
    VueAutosuggest,
    BDropdown,
    BDropdownItem,
    BButton,
    // BBadge,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormCheckbox,
    // BFormInput,
    BCard,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BCardHeader,
  },
  props: {
    dienteOdontogramaId: {
      type: Number,
      default: 0,
    },
    paciente: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nombrediente: '',
      codigoDienteSelected: '',
      dataDienteSelect: {
        diente: { url_ausente: 'xdiente.png' },
      },
      texto_create_update: 'NUEVA ATENCIÓN',
      color_create_update: 'info',
      texto_boton_create_update: 'grabar atencion',
      observaciones: '',
      atencionesDiente: [],
      fila_a_editar: {},
      shownNuevaatencion: false,
      dir: 'ltr',
      option: [{ title: 'Curación dental' }, { title: 'Extracción' }, { title: 'Limpieza' }],
      optionsEstados: [],
      selectedEstados: [],
      historiaDental: [],
      fieldHistorial: [
        {
          key: 'created_at', label: 'Fecha Registro',
        },
        {
          key: 'observacion', label: 'Observaciones', sortable: true, class: 'text-left',
        },
        // { key: 'tratamiento', label: 'Tratamiento' },
        { key: 'show_diagnosticos_procedimientos', label: 'Detalles' },
        { key: 'actions', label: '' },
      ],

      // suggest Diagnos
      queryDiagnos: '',
      timeout: null,
      diagnosticos: [],
      debounceMilliseconds: 500,
      inputPropsDiagnos: {
        // id: 'autosuggest__input_ajax_Diagnos',
        placeholder: 'Ingrese código / descripción.',
        class: 'form-control',
        // name: 'ajax',
      },
      suggestionsDiagnos: [],
      sectionConfigsDiagnos: {
        destinations: {
          limit: 50,
          label: 'Diagnósticos',
          onSelected: selected => {
            // this.selected = selected.item
            this.diagnosticos.push(selected.item)
          },
        },
      },
      fieldsDiagnosticos: [
        {
          key: 'codigo', label: 'CIE 10', sortable: true, class: 'text-left',
        },
        { key: 'descripcion' },
        { key: 'actions', label: 'Quitar' },
      ],

      // suggest Procedim
      queryProcedim: '',
      procedimientos: [],
      inputPropsProcedim: {
        placeholder: 'Ingrese código / descripción.',
        class: 'form-control',
      },
      suggestionsProcedim: [],
      sectionConfigsProcedim: {
        destinations: {
          limit: 50,
          label: 'Procedimientos',
          onSelected: selected => {
            this.procedimientos.push(selected.item)
          },
        },
      },
      fieldsProcedimientos: [
        {
          key: 'codigo_procedimiento', label: 'Código', sortable: true, class: 'text-left',
        },
        { key: 'denominacion_procedimiento' },
        { key: 'actions', label: 'Quitar' },
      ],

      isBusy: false,

      // estilos
      porTratar: 'porTratar',
      tratado: 'tratado',
      enTratamiento: 'enTratamiento',
    }
  },
  created() {
    this.$parent.$on('cargarDatosDienteSelect', this.cargarDatosDienteSelect)
  },
  mounted() {
    this.optionsEstados = []
    store.dispatch('atencion-diente-module/getEstadosDiente')
      .then(response => {
        response.data.forEach(value => {
          this.optionsEstados.push({ value: value.id, text: value.nombre, color: value.color })
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    nuevaAtencion() {
      this.texto_create_update = 'NUEVA ATENCIÓN'
      this.color_create_update = 'bg-light-info'
      this.texto_boton_create_update = 'grabar atencion'
      this.diagnosticos = []
      this.procedimientos = []
      this.observaciones = ''
      this.btn_create_update = true // true: crear nueva atencion
      this.shownNuevaatencion = true
    },
    toastSuccess(text, tipo, titulo) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titulo,
          icon: 'BellIcon',
          text,
          variant: tipo,
        },
      })
    },
    clickbtnAtencionDiente() {
      if (this.btn_create_update) {
        this.saveAtencion()
      } else {
        this.updateAtencion()
      }
    },
    saveAtencion() {
      const data = {
        atenciones_id: this.$router.currentRoute.params.id,
        diente_odontograma_id: this.dataDienteSelect.id,
        observacion: this.observaciones,
        diagnosticos: this.diagnosticos.map(value => ({ diagnostico_id: value.id })),
        procedimientos: this.procedimientos.map(value2 => ({ procedimiento_id: value2.id })),
      }
      store.dispatch('atencion-diente-module/addAtencionDiente', data)
        .then(() => {
          this.toastSuccess('Atención grabada', 'success', '¡Éxito!')
          this.cargarAtencionesDiente(this.dataDienteSelect.id)
          this.shownNuevaatencion = false
          this.diagnosticos = []
          this.procedimientos = []
          this.observaciones = ''
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
        })
    },
    updateAtencion() {
      const data = {
        atencionDiente_id: this.fila_a_editar.id,
        atenciones_id: this.$router.currentRoute.params.id,
        diente_odontograma_id: this.dataDienteSelect.id,
        observacion: this.observaciones,
        diagnosticos: this.diagnosticos.map(value => ({ diagnostico_id: value.id })),
        procedimientos: this.procedimientos.map(value2 => ({ procedimiento_id: value2.id })),
      }
      // console.log('DATA A ACTUALIZAR: ', data)
      store.dispatch('atencion-diente-module/updateAtencionDiente', data)
        .then(() => {
          this.toastSuccess('Atención actualizada', 'success', '¡Éxito!')
          this.cargarAtencionesDiente(this.dataDienteSelect.id)
          this.shownNuevaatencion = false
          this.diagnosticos = []
          this.procedimientos = []
          this.observaciones = ''
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
        })
    },
    deleteAtencionDiente(idAtencionDiente) {
      const data = {
        atencionDiente_id: idAtencionDiente,
        diente_odontograma_id: this.dataDienteSelect.id,
      }
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar atención!',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('atencion-diente-module/deleteAtencionDiente', data)
            .then(() => {
              this.toastSuccess('Atención eliminada', 'success', '¡Éxito!')
              this.cargarAtencionesDiente(this.dataDienteSelect.id)
            })
          // eslint-disable-next-line no-unused-vars
            .catch(error => {
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'La atención se mantiene :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    cargarDatosDienteSelect(data) {
      // console.log('DIENTE CARGADO DATOS: ', data)
      this.codigoDienteSelected = data.diente.codigo
      this.dataDienteSelect = data
      this.selectedEstados = []
      this.nombrediente = data.diente.nombre
      this.selectedEstados = data.estados.map(value2 => value2.id)

      this.cargarAtencionesDiente(this.dataDienteSelect.id)
    },
    cargarAtencionesDiente(dienteId) {
      store.dispatch('atencion-diente-module/getAtencionesDiente', dienteId)
        .then(response => {
          // console.log('AtencionDienteSeleccionado: ', response)
          this.atencionesDiente = response.data.data
        })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
        })
    },
    editarAtencionDiente(item) {
      // console.log('ITEM SELECCIONADO: ', item)
      this.fila_a_editar = item
      const data = {
        dienteId: this.dataDienteSelect.id,
        atencionDienteId: item.id,
      }
      store.dispatch('atencion-diente-module/getAtencionDiente', data)
        .then(response => {
          // console.log('Atenciondiente seleccionado: ', response)
          this.diagnosticos = response.data.diagnosticos
          this.procedimientos = response.data.procedimientos
          this.observaciones = response.data.observacion
          this.texto_create_update = 'EDITAR ATENCIÓN'
          this.color_create_update = 'bg-light-warning'
          this.texto_boton_create_update = 'actualizar atención'
          this.btn_create_update = false // false: actualizar nueva atencion
          this.shownNuevaatencion = true
        })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
        })
    },
    actualizarEstados() {
      const data = {
        dienteOdontogramaId: this.dataDienteSelect.id,
        estados: this.selectedEstados,
      }
      store.dispatch('atencion-diente-module/updateEstados', data)
        .then(response => {
          // console.log('Estados actualizados: ', response)

          this.dataDienteSelect.array_estados = response.data.map(value => value.nombre)
          console.log('dataDienteSelect.array_estados: ', this.dataDienteSelect.array_estados)
          const diente = {
            codigoDiente: this.codigoDienteSelected,
            estadosDiente: response.data,
          }
          this.$emit('actualizarEstadosOdontogramaMostrado', diente)
        })
        .catch(error => {
          console.log(error)
        })

      console.log('estadosActivos: ', this.selectedEstados)
      console.log('dienteSelectId: ', this.dataDienteSelect.id)
    },

    // suggestions Diagnosticos
    renderSuggestionDiagnos(suggestion) {
      return (
          <div>
            <div class="text-danger font-weight-bold">
              {suggestion.item.codigo}
            </div>
            <div>
              <span>{suggestion.item.descripcion}</span>
            </div>
          </div>
      )
      // return suggestion.item.codigo + suggestion.item.descripcion
    },
    // eslint-disable-next-line no-unused-vars
    getSuggestionValueDiagnos(suggestion) {
      /* const { codigo, item } = suggestion
      return codigo === 'hotels' ? item.title : `${item.codigo} ${item.descripcion}` */
    },
    fetchResultsDiagnos() {
      console.log('entre')
      const query = this.queryDiagnos
      if (query.length < 3) {
        return
      }
      const servicioId = 2
      const perPage = 50
      // const servicioId = 2
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const dPromise = this.$http.get('diagnosticos', { params: { query, perPage, servicioId } })
        dPromise.then(response => {
          console.log('response: ', response)
          this.suggestionsDiagnos = []
          this.selected = null
          const dataDiagnos = response.data.data
          // eslint-disable-next-line no-unused-expressions
          dataDiagnos.length && this.suggestionsDiagnos.push({ name: 'destinations', data: dataDiagnos })
        })
      }, this.debounceMilliseconds)
    },
    quitarDiagnostico(item) {
      this.diagnosticos.splice(this.diagnosticos.indexOf(item), 1)
    },

    // suggestions Procedim
    renderSuggestionProcedim(suggestion) {
      return (
          <div>
            <div class="text-danger font-weight-bold">
              {suggestion.item.codigo_procedimiento}
            </div>
            <div>
              <span>{suggestion.item.denominacion_procedimiento}</span>
            </div>
          </div>
      )
    },
    // eslint-disable-next-line no-unused-vars
    getSuggestionValueProcedim(suggestion) {
      /* const { codigo, item } = suggestion
      return codigo === 'hotels' ? item.title : `${item.codigo} ${item.descripcion}` */
    },
    fetchResultsProcedim() {
      const { queryProcedim } = this
      if (queryProcedim.length < 3) {
        return
      }
      const perPage = 50

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const pPromise = this.$http.get('procedimientos', { params: { queryProcedim, perPage } })
        pPromise.then(response => {
          console.log('procedimeintos: ', response)
          this.suggestionsProcedim = []
          // this.selected = null
          const dataProced = response.data.data
          // eslint-disable-next-line no-unused-expressions
          dataProced.length && this.suggestionsProcedim.push({ name: 'destinations', data: dataProced })
        })
      }, this.debounceMilliseconds)
    },
    quitarProcedimiento(item) {
      this.procedimientos.splice(this.procedimientos.indexOf(item), 1)
    },

  },
  setup() {
    const ATENCION_DIENTE_MODULE_NAME = 'atencion-diente-module'

    // Register module
    if (!store.hasModule(ATENCION_DIENTE_MODULE_NAME)) store.registerModule(ATENCION_DIENTE_MODULE_NAME, storeDienteAtencion)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ATENCION_DIENTE_MODULE_NAME)) store.unregisterModule(ATENCION_DIENTE_MODULE_NAME)
    })

    return {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.porTratar {
        border-bottom: solid 4px #ff0000,
      }
.tratado {
        border-top: solid 4px #0101DF,
      }
.enTratamiento {
        border-left: solid 4px #108002,
      }
</style>
