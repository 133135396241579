<template>
  <div>
    <b-modal
      id="modal_receta"
      ok-only
      size="lg"
      class="p-5"
      title="Receta"
      title-class="text-white"
      header-bg-variant="primary"
    >
      <b-card>
        <b-row class="text-center">
          <b-col md="12">
            <h5>
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              Paciente: <span class="text-primary">{{ paciente }} - {{ pacDocumentoIdentidad }}</span>
              <b-button
                v-if="!mostrarFormulario"
                class="ml-4"
                variant="danger"
                size="sm"
                @click="deleteItem"
              >Eliminar receta</b-button>
            </h5>
            <h4
              v-if="mostrarReceta"
              class="mb-0"
            >
              {{ receta.serie }} -  <strong class="text-danger">{{ receta.show_numero_receta }}</strong>
            </h4>
            <span
              v-if="mostrarReceta"
              class="text-muted"
            >{{ receta.created_at }}</span> <br>
          </b-col>
          <b-col>
            <b-button
              v-if="mostrarFormulario"
              variant="primary"
              :disabled="estaCreando"
              @click="crearReceta"
            >
              <b-spinner
                v-if="estaCreando"
                small
                type="grow"
              />
              {{ estaCreando ? 'Generando...' : 'Generar receta' }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-card
        v-if="!mostrarFormulario"
      >
        <b-row>
          <b-col md="12">
            <div class="d-flex">
              <feather-icon
                icon="PlusCircleIcon"
                size="19"
              />
              <h5 class="mb-0 ml-50">
                Adicionar medicamento
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <!-- <b-col cols="12">
            <label>Medicamento</label>
            <v-select
              v-model="medSeleccionado"
              :filterable="false"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="titleMedicamento"
              :options="options"
              placeholder="Buscar medicamento"
              @search="onSearch"
              @focus="onSearch"
              @input="onSelectSearch"
            >
              <template #selected-option="{title}">
                <div style="display: flex; align-items: baseline">
                  <strong>{{ title }}</strong>
                </div>
              </template>
            </v-select>
          </b-col> -->
          <b-col cols="12">
            <v-select
              v-model="medSeleccionado"
              :options="options"
              label="titleMedicamento"
              :filterable="false"
              placeholder="Buscar medicamento"
              @search="onSearch"
              @option:selected="onSelectSearch"
            >
              <template slot="no-options">
                Lo sentimos no hay opciones coincidentes...
              </template>
            </v-select>
          </b-col>
          <!-- <b-col cols="2">
            <label>Cantidad</label>
            <b-form-input
              v-model="cantidad"
              type="number"
            />
          </b-col>
          <b-col
            cols="1"
            class="mt-2"
          >
            <b-button
              size="md"
              variant="gradient-success"
              class="mb-1 px-1"
              @click="addMedicamento"
            >
              <feather-icon
                icon="PlusCircleIcon"
                size="19"
              />
            </b-button>
          </b-col> -->
          <!-- <b-col cols="12">
            <label>Indicaciones: </label>
            <b-form-textarea
              v-model="indicaciones"
              rows="2"
              placeholder="Detalle sus indicaciones"
            />
          </b-col> -->
        </b-row>
      </b-card>
      <b-card
        v-if="!mostrarFormulario"
        class="mt-1"
      >
        <b-row>
          <b-col md="12">
            <div class="d-flex">
              <feather-icon
                icon="ListIcon"
                size="19"
              />
              <h5 class="mb-1 ml-50">
                Lista de medicamentos
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-table
          :items="listaMedicamentos"
          :fields="fields_listaMedicamentos"
          :busy.sync="isBusy"
          size="sm"
          sticky-header="250px"
          small
          head-variant="light"
          :empty-text="'No hay registros para mostrar'"
        >
          <template #cell(medicamento)="row">
            {{ row.item.medicamento.nombre_corto }}
          </template>
          <template #cell(show_indicaciones)="row">
            <span
              class="btn text-center"
            >
              <feather-icon
                :icon="row.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                size="19"
                @click="row.toggleDetails"
              />
            </span>

          </template>

          <template
            #row-details="row"
          >
            <b-card class="m-0 p-0">
              <b-row>
                <b-col
                  sm="3"
                  class="text-sm-right"
                >
                  <b>Indicaciones:</b>
                </b-col>
                <b-col>{{ row.item.indicaciones }}</b-col>
              </b-row>
            </b-card>
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              variant="outline-warning"
              @click="editarMedicamento(row.item)"
            >
              <feather-icon
                icon="Edit2Icon"
                size="12"
              />
            </b-button>
            <b-button
              size="sm"
              variant="outline-danger"
              @click="quitarMedicamento(row.item)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="12"
              />
            </b-button>
          </template>
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
        </b-table>
      </b-card>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right"
            @click="imprimirReceta"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            <span class="align-middle">Imprimir</span>
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal_editar_medicam_receta"
      ok-only
      size="sm"
      class="p-5"
      title="Editar medicamento"
      centered
    >
      <b-card>
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Medicamento"
                label-for="edit_medicamento"
                label-cols-md="4"
              >
                <b-form-input
                  id="edit_medicamento"
                  v-model="edit_medicam.medicamento_nombre"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Cantidad"
                label-for="edit_cantidad"
                label-cols-md="4"
              >
                <b-form-input
                  id="edit_cantidad"
                  v-model="edit_medicam.cantidad"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Indicaciones"
                label-for="edit_indicaciones"
                label-cols-md="4"
              >
                <b-form-textarea
                  id="adit_indicaciones"
                  v-model="edit_medicam.indicaciones"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

      </b-card>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-success"
            size="sm"
            class="float-right"
            @click="updateMedicamentoReceta"
          >
            Actualizar <feather-icon
              icon="RefreshCwIcon"
              size="19"
            />
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BTable, BRow, BCol, BFormInput, BCard, BFormTextarea, BFormGroup, BForm, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import { onUnmounted } from '@vue/composition-api'
import atencionRecetaStore from '@/views/salud/atencion/atencion-receta/atencionRecetaStore'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    vSelect,
    BButton,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BCard,
    BFormTextarea,
    BFormGroup,
    BForm,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    idPaciente: {
      type: Number,
      default: null,
    },
    paciente: {
      type: String,
      default: '',
    },
    pacDocumentoIdentidad: {
      type: String,
      default: '',
    },
    receta: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      estaCreando: false,
      mostrarFormulario: false,
      mostrarReceta: false,
      atencion_id: router.currentRoute.params.id,
      edit_medicam: {
        id: null,
        receta_id: null,
        medicamento_id: null,
        cantidad: null,
        indicaciones: null,
        medicamento_nombre: null,
      },
      editDefault: {
        id: null,
        receta_id: null,
        medicamento_id: null,
        cantidad: null,
        indicaciones: null,
        medicamento_nombre: null,
      },
      medSeleccionado: null,
      options: [],
      newMedicamento: {},
      cantidad: 1,
      indicaciones: '',
      dir: 'ltr',
      selected: [
      ],
      listaMedicamentos: [],
      fields_listaMedicamentos: [
        {
          key: 'index', label: 'N°',
        },
        {
          key: 'medicamento', label: 'Medicamento', sortable: true, class: 'text-left',
        },
        { key: 'cantidad', label: 'Cantidad' },
        { key: 'show_indicaciones', label: 'Indicaciones' },
        { key: 'actions', label: 'Acciones' },
      ],
      isBusy: false,
      cadena_a_buscar: '',
      mensajeMinimo: false,
    }
  },
  mounted() {
    this.showReceta()
  },
  methods: {
    showReceta() {
      if (this.receta !== null) {
        store.dispatch('receta-module/showReceta', this.receta.id)
          .then(response => {
            this.recetaDetalles = response.data
            this.listaMedicamentos = response.data.receta_detalles
            this.mostrarReceta = true
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.mostrarFormulario = true
      }
    },
    crearReceta() {
      this.estaCreando = true
      const data = {
        atencion_id: router.currentRoute.params.id,
        paciente_id: this.idPaciente,
      }
      store.dispatch('receta-module/addReceta', data).then(response => {
        this.toastSuccess('Receta creada')
        this.estaCreando = false
        // this.receta = response.data
        this.$emit('update-receta-item', response.data)
        this.mostrarReceta = true
        this.mostrarFormulario = false
      }).catch(error => {
        if (error.response.data.code === 406) {
          this.toastError(`${error.response.data.error}`)
          this.estaCreando = false
        } else {
          Object.entries(error.response.data.error).forEach(([, value]) => {
            this.toastError(`${value[0]}`)
          })
        }
      })
    },
    addMedicamento() {
      // console.log('idAtencion: ', router.currentRoute.params)
      const data = {
        receta_id: this.receta.id,
        medicamento_id: this.medSeleccionado.value,
        cantidad: this.cantidad,
        indicaciones: this.indicaciones,
      }
      store.dispatch('receta-module/addMedicamentoReceta', data).then(response => {
        this.toastSuccess('Medicamento adicionado')
        this.cantidad = 1
        this.indicaciones = null
        this.medSeleccionado = []
        this.listaMedicamentos = response.data.receta_detalles
      }).catch(error => {
        if (error.response.data.code === 406) {
          this.toastError(`${error.response.data.error}`)
        } else {
          Object.entries(error.response.data.error).forEach(([, value]) => {
            // this.toastError(`${value[0]}`, 'top-right', `${key.toUpperCase()}`)
            this.toastError(`${value[0]}`)
          })
        }
      })
    },
    quitarMedicamento(item) {
      store.dispatch('receta-module/removeMedicamentoReceta', item)
        .then(response => {
          this.toastSuccess('Medicamento quitado')
          this.listaMedicamentos = response.data.receta_detalles
        }).catch(error => {
          this.toastError(`${error.response.data.error}`)
        })
    },
    editarMedicamento(medicamento) {
      this.edit_medicam.id = medicamento.id
      this.edit_medicam.receta_id = this.receta.id
      this.edit_medicam.medicamento_id = medicamento.medicamento_id
      this.edit_medicam.cantidad = medicamento.cantidad
      this.edit_medicam.indicaciones = medicamento.indicaciones
      this.edit_medicam.medicamento_nombre = medicamento.medicamento.nombre_corto
      this.$bvModal.show('modal_editar_medicam_receta')
    },
    updateMedicamentoReceta() {
      store.dispatch('receta-module/updateMedicamentoReceta', this.edit_medicam).then(response => {
        this.toastSuccess('Medicamento actualizado!')
        this.listaMedicamentos = response.data.receta_detalles
        this.$bvModal.hide('modal_editar_medicam_receta')
        this.edit_medcam = this.editDefault
      }).catch(error => {
        if (error.response.data.code === 406) {
          this.toastError(`${error.response.data.error}`)
        } else {
          Object.entries(error.response.data.error).forEach(([key, value]) => {
            this.toastError(`${value[0]}`, 'top-right', `${key.toUpperCase()}`)
          })
        }
      })
    },
    imprimirReceta() {
      this.$router.replace({ name: 'download-pdf-receta', params: { id: this.receta.codigo_identificador } })
    },
    onSearch(search, loading) {
      // console.log('epaaaaaaaa', search)
      if (search.length > 4) {
        loading(true)
        const params = { filter: search }
        store.dispatch('receta-module/fetchMedicamento', params)
          .then(response => {
            if (response.data.data.length) {
              this.options = response.data.data.map(val => ({
                value: val.id, titleMedicamento: `${val.nombre_completo} (${val.stock})`, title: val.nombre_completo,
              }))
            } else {
              this.toastAlert('No se encontraron registros')
            }
            loading(false)
          }).catch(error => {
            console.log(error)
            this.toastError()
          })
      }
    },
    onSelectSearch() {
      this.addMedicamento()
    },
    deleteItem() {
      // Implemente swal
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, bórralo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('receta-module/deleteReceta', this.receta)
            .then(() => {
              this.$swal({
                icon: 'success',
                title: 'Eliminado!',
                text: 'Tu receta ha sido eliminado.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              // this.actualizarTabla()
              this.listaMedicamentos = []
              this.estaCreando = false
              this.$emit('update-receta-item', {})
              this.mostrarFormulario = true
              this.mostrarReceta = false
            }).catch(error => {
              this.$swal({
                title: 'Error',
                text: `${error.response.data.error}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Cancelado',
            text: 'Tu receta está seguro :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
  setup() {
    const RECETA_STORE_MODULE_NAME = 'receta-module'

    // Register module
    if (!store.hasModule(RECETA_STORE_MODULE_NAME)) store.registerModule(RECETA_STORE_MODULE_NAME, atencionRecetaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RECETA_STORE_MODULE_NAME)) store.unregisterModule(RECETA_STORE_MODULE_NAME)
    })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
