<template>
  <div>
    <b-modal
      id="modal_procedimiento"
      size="lg"
      title="Agregar procedimientos"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      cancel-title="Cerrar"
      modal-class="modal-success"
    >
      <b-row>
        <b-col
          lg="10"
          md="12"
        >
          <label>Procedimiento</label>
          <vue-autosuggest
            ref="autocomplete"
            v-model="query"
            :suggestions="suggestions"
            :input-props="inputProps"
            :section-configs="sectionConfigs"
            :render-suggestion="renderSuggestion"
            :get-suggestion-value="getSuggestionValue"
            @input="fetchResults"
          />

        </b-col>
        <b-col
          lg="2"
          md="12"
          class="mt-2"
        >
          <b-button
            class="mb-0"
            variant="success"
            block
            @click="actualizarprocedimientos()"
          >
            Grabar
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        :items="procedimientos"
        :fields="fields"
        :busy.sync="isBusy"
        size="sm"
        sticky-header="250px"
        small
        head-variant="light"
        :empty-text="'No hay registros para mostrar'"
      >
        <template #cell(index)="row">
          {{ row.index +1 }}
        </template>
        <template #cell(actions)="row">
          <b-button
            size="sm"
            variant="outline-danger"
            @click="quitarprocedimiento(row.item)"
          >
            <feather-icon
              icon="Trash2Icon"
              size="12"
            />
          </b-button>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BButton, BTable, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

import router from '@/router'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BTable,
    VueAutosuggest,
    BAvatar,
  },
  data() {
    return {
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 500,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Ingrese código / descripción.',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        destinations: {
          limit: 50,
          label: 'Procedimientos',
          onSelected: selected => {
            this.selected = selected.item
            this.procedimientos.push(selected.item)
          },
        },
      },
      atencionId: router.currentRoute.params.id,
      procedimientos: [],
      fields: [
        { key: 'index', label: 'N°' },
        {
          key: 'codigo_procedimiento', label: 'Código', sortable: true, class: 'text-left',
        },
        { key: 'denominacion_procedimiento', label: 'Procedimiento' },
        { key: 'actions', label: 'Acciones' },
      ],
      isBusy: false,
    }
  },
  mounted() {
    this.$http.get(`atenciones/${this.atencionId}/procedimientos`)
      .then(response => {
        this.procedimientos = response.data.data
      })
  },
  methods: {
    quitarprocedimiento(item) {
      this.procedimientos.splice(this.procedimientos.indexOf(item), 1)
    },
    actualizarprocedimientos() {
      const data = this.procedimientos.map(item => item.id)
      this.$http.put(`atenciones/${this.atencionId}/procedimientos`, { procedimientos: data })
        .then(response => {
          this.selected = null
          document.getElementsByName('ajax')[0].value = null
          this.procedimientos = response.data.data
          this.$emit('actualizar-procedimientos-atencion', response.data.data)
        }).catch(error => {
          this.toastError(`${error.response.data.error}`)
        })
    },
    fetchResults() {
      const { query } = this
      if (query.length < 3) {
        return
      }
      const perPage = 50

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const photosPromise = this.$http.get('procedimientos', { params: { query, perPage } })
        photosPromise.then(response => {
          this.suggestions = []
          this.selected = null
          const users = response.data.data
          // eslint-disable-next-line no-unused-expressions
          users.length && this.suggestions.push({ name: 'destinations', data: users })
        })
      }, this.debounceMilliseconds)
    },
    renderSuggestion(suggestion) {
      // if (suggestion.name === 'hotels') {
      //   const image = suggestion.item
      //   return (
      //     <div class="d-flex">
      //       <div>
      //         <b-avatar src={image.thumbnailUrl} class="mr-50"></b-avatar>
      //       </div>
      //       <div>
      //         <span>{image.title}</span>
      //       </div>
      //     </div>
      //   )
      // }
      return (
          <div>
            <div class="text-danger font-weight-bold">
              {suggestion.item.codigo_procedimiento}
            </div>
            <div>
              <span>{suggestion.item.denominacion_procedimiento}</span>
            </div>
          </div>
      )
      // return suggestion.item.codigo + suggestion.item.descripcion
    },
    getSuggestionValue(suggestion) {
      const { codigo, item } = suggestion
      return codigo === 'hotels' ? item.title : `${item.codigo_procedimiento} ${item.denominacion_procedimiento}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
