<template>
  <div>
    <div v-if="result">
      <!--
      <div
        id="cursorMouse"
        class="mx-2"
      /> -->
      <b-card
        class="text-center m-0 p-0"
      >
        <b-row class="mb-1 float-left">

          <b-col
            md="12"
            class="d-flex justify-content-start border pt-1 rounded"
          >
            <p> Por tratar: </p><span>
              <div
                class="mx-2"
                style="width:50px; border-radius:5px; height:10px; background:red; margin-top:5px;"
              />
            </span>
            <p>
              Tratado:
            </p><span>
              <div
                class="mx-2"
                style="width:50px; border-radius:5px; height:10px; background:blue; margin-top:5px;"
              />
            </span>
            <p>
              En tratamiento:
            </p><span>
              <div
                class="mx-2"
                style="width:50px; border-radius:5px; height:10px; background:green; margin-top:5px;"
              />
            </span>
          </b-col>
        </b-row>
        <b-row class="mb-1 float-right">
          <b-col
            md="12"
            class="d-flex justify-content-start border pt-1 rounded"
          >

            <b-form-checkbox
              v-model="checked"
              switch
              @change="ftacharDiente"
            >
              Tachar - Deshacer tachado diente <b />
            </b-form-checkbox>

          </b-col>
        </b-row>
        <b-table-simple
          small
          responsive
          borderless
          class="my-2"
        >
          <b-tbody>
            <b-tr>
              <b-td><b-form-input
                v-model="arrayDientes[18].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="18"
                @update="updateInput(18)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[17].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="17"
                @update="updateInput(17)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[16].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="16"
                @update="updateInput(16)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[15].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="15"
                @update="updateInput(15)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[14].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="14"
                @update="updateInput(14)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[13].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="13"
                @update="updateInput(13)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[12].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="12"
                @update="updateInput(12)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[11].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="11"
                @update="updateInput(11)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[21].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="21"
                @update="updateInput(21)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[22].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="22"
                @update="updateInput(22)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[23].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="23"
                @update="updateInput(23)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[24].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="24"
                @update="updateInput(24)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[25].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="25"
                @update="updateInput(25)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[26].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="26"
                @update="updateInput(26)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[27].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="27"
                @update="updateInput(27)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[28].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="28"
                @update="updateInput(28)"
              /></b-td>
            </b-tr>
            <b-tr>
              <b-td />
              <b-td />
              <b-td />
              <b-td><b-form-input
                v-model="arrayDientes[55].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="55"
                @update="updateInput(55)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[54].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="54"
                @update="updateInput(54)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[53].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="53"
                @update="updateInput(53)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[52].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="52"
                @update="updateInput(52)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[51].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="51"
                @update="updateInput(51)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[61].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="61"
                @update="updateInput(61)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[62].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="62"
                @update="updateInput(62)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[63].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="63"
                @update="updateInput(63)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[64].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="64"
                @update="updateInput(64)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[65].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="65"
                @update="updateInput(65)"
              /></b-td>
              <b-td />
              <b-td />
              <b-td />
            </b-tr>
            <b-tr>
              <b-td>
                <h6 class="text-center">
                  18
                </h6>
                <img
                  ref="18"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[18].presencialidad == 'presente' ? arrayDientes[18].diente.url_presente : arrayDientes[18].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0"
                  :class="arrayDientes[18].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(18)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  17
                </h6>
                <img
                  ref="17"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[17].presencialidad == 'presente' ? arrayDientes[17].diente.url_presente : arrayDientes[17].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0"
                  :class="arrayDientes[17].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(17)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  16
                </h6>
                <img
                  ref="16"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[16].presencialidad == 'presente' ? arrayDientes[16].diente.url_presente : arrayDientes[16].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0"
                  :class="arrayDientes[16].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(16)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  15
                </h6>
                <img
                  ref="15"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[15].presencialidad == 'presente' ? arrayDientes[15].diente.url_presente : arrayDientes[15].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[15].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(15)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  14
                </h6>
                <img
                  ref="14"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[14].presencialidad == 'presente' ? arrayDientes[14].diente.url_presente : arrayDientes[14].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[14].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(14)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  13
                </h6>
                <img
                  ref="13"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[13].presencialidad == 'presente' ? arrayDientes[13].diente.url_presente : arrayDientes[13].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[13].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(13)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  12
                </h6>
                <img
                  ref="12"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[12].presencialidad == 'presente' ? arrayDientes[12].diente.url_presente : arrayDientes[12].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[12].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(12)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  11
                </h6>
                <img
                  ref="11"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[11].presencialidad == 'presente' ? arrayDientes[11].diente.url_presente : arrayDientes[11].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[11].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(11)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  21
                </h6>
                <img
                  ref="21"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[21].presencialidad == 'presente' ? arrayDientes[21].diente.url_presente : arrayDientes[21].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[21].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(21)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  22
                </h6>
                <img
                  ref="22"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[22].presencialidad == 'presente' ? arrayDientes[22].diente.url_presente : arrayDientes[22].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[22].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(22)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  23
                </h6>
                <img
                  ref="23"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[23].presencialidad == 'presente' ? arrayDientes[23].diente.url_presente : arrayDientes[23].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[23].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(23)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  24
                </h6>
                <img
                  ref="24"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[24].presencialidad == 'presente' ? arrayDientes[24].diente.url_presente : arrayDientes[24].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[24].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(24)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  25
                </h6>
                <img
                  ref="25"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[25].presencialidad == 'presente' ? arrayDientes[25].diente.url_presente : arrayDientes[25].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[25].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(25)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  26
                </h6>
                <img
                  ref="26"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[26].presencialidad == 'presente' ? arrayDientes[26].diente.url_presente : arrayDientes[26].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[26].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(26)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  27
                </h6>
                <img
                  ref="27"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[27].presencialidad == 'presente' ? arrayDientes[27].diente.url_presente : arrayDientes[27].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[27].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(27)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  28
                </h6>
                <img
                  ref="28"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[28].presencialidad == 'presente' ? arrayDientes[28].diente.url_presente : arrayDientes[28].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[28].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(28)"
                ></b-td>
            </b-tr>
            <b-tr>
              <b-td />
              <b-td />
              <b-td />
              <b-td>
                <h6 class="text-center">
                  55
                </h6>
                <img
                  ref="55"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[55].presencialidad == 'presente' ? arrayDientes[55].diente.url_presente : arrayDientes[55].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[55].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(55)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  54
                </h6>
                <img
                  ref="54"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[54].presencialidad == 'presente' ? arrayDientes[54].diente.url_presente : arrayDientes[54].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[54].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(54)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  53
                </h6>
                <img
                  ref="53"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[53].presencialidad == 'presente' ? arrayDientes[53].diente.url_presente : arrayDientes[53].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[53].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(53)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  52
                </h6>
                <img
                  ref="52"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[52].presencialidad == 'presente' ? arrayDientes[52].diente.url_presente : arrayDientes[52].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[52].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(52)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  51
                </h6>
                <img
                  ref="51"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[51].presencialidad == 'presente' ? arrayDientes[51].diente.url_presente : arrayDientes[51].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[51].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(51)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  61
                </h6>
                <img
                  ref="61"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[61].presencialidad == 'presente' ? arrayDientes[61].diente.url_presente : arrayDientes[61].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[61].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(61)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  62
                </h6>
                <img
                  ref="62"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[62].presencialidad == 'presente' ? arrayDientes[62].diente.url_presente : arrayDientes[62].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[62].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(62)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  63
                </h6>
                <img
                  ref="63"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[63].presencialidad == 'presente' ? arrayDientes[63].diente.url_presente : arrayDientes[63].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[63].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(63)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  64
                </h6>
                <img
                  ref="64"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[64].presencialidad == 'presente' ? arrayDientes[64].diente.url_presente : arrayDientes[64].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[64].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(64)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  65
                </h6>
                <img
                  ref="65"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[65].presencialidad == 'presente' ? arrayDientes[65].diente.url_presente : arrayDientes[65].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[65].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(65)"
                ></b-td>
              <b-td />
              <b-td />
              <b-td />
            </b-tr>
            <b-tr>
              <b-td />
              <b-td />
              <b-td />
              <b-td>
                <h6 class="text-center">
                  85
                </h6>
                <img
                  ref="85"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[85].presencialidad == 'presente' ? arrayDientes[85].diente.url_presente : arrayDientes[85].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[85].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(85)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  84
                </h6>
                <img
                  ref="84"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[84].presencialidad == 'presente' ? arrayDientes[84].diente.url_presente : arrayDientes[84].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[84].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(84)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  83
                </h6>
                <img
                  ref="83"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[83].presencialidad == 'presente' ? arrayDientes[83].diente.url_presente : arrayDientes[83].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[83].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(83)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  82
                </h6>
                <img
                  ref="82"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[82].presencialidad == 'presente' ? arrayDientes[82].diente.url_presente : arrayDientes[82].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[82].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(82)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  81
                </h6>
                <img
                  ref="81"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[81].presencialidad == 'presente' ? arrayDientes[81].diente.url_presente : arrayDientes[81].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[81].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(81)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  71
                </h6>
                <img
                  ref="71"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[71].presencialidad == 'presente' ? arrayDientes[71].diente.url_presente : arrayDientes[71].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[71].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(71)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  72
                </h6>
                <img
                  ref="72"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[72].presencialidad == 'presente' ? arrayDientes[72].diente.url_presente : arrayDientes[72].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[72].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(72)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  73
                </h6>
                <img
                  ref="73"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[73].presencialidad == 'presente' ? arrayDientes[73].diente.url_presente : arrayDientes[73].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[73].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(73)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  74
                </h6>
                <img
                  ref="74"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[74].presencialidad == 'presente' ? arrayDientes[74].diente.url_presente : arrayDientes[74].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[74].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(74)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  75
                </h6>
                <img
                  ref="75"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[75].presencialidad == 'presente' ? arrayDientes[75].diente.url_presente : arrayDientes[75].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[75].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(75)"
                ></b-td>
              <b-td />
              <b-td />
              <b-td />
            </b-tr>
            <b-tr>
              <b-td>
                <h6 class="text-center">
                  48
                </h6>
                <img
                  ref="48"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[48].presencialidad == 'presente' ? arrayDientes[48].diente.url_presente : arrayDientes[48].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[48].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(48)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  47
                </h6>
                <img
                  ref="47"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[47].presencialidad == 'presente' ? arrayDientes[47].diente.url_presente : arrayDientes[47].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[47].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(47)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  46
                </h6>
                <img
                  ref="46"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[46].presencialidad == 'presente' ? arrayDientes[46].diente.url_presente : arrayDientes[46].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[46].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(46)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  45
                </h6>
                <img
                  ref="45"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[45].presencialidad == 'presente' ? arrayDientes[45].diente.url_presente : arrayDientes[45].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[45].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(45)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  44
                </h6>
                <img
                  ref="44"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[44].presencialidad == 'presente' ? arrayDientes[44].diente.url_presente : arrayDientes[44].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[44].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(44)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  43
                </h6>
                <img
                  ref="43"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[43].presencialidad == 'presente' ? arrayDientes[43].diente.url_presente : arrayDientes[43].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[43].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(43)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  42
                </h6>
                <img
                  ref="42"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[42].presencialidad == 'presente' ? arrayDientes[42].diente.url_presente : arrayDientes[42].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[42].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(42)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  41
                </h6>
                <img
                  ref="41"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[41].presencialidad == 'presente' ? arrayDientes[41].diente.url_presente : arrayDientes[41].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[41].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(41)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  31
                </h6>
                <img
                  ref="31"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[31].presencialidad == 'presente' ? arrayDientes[31].diente.url_presente : arrayDientes[31].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[31].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(31)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  32
                </h6>
                <img
                  ref="32"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[32].presencialidad == 'presente' ? arrayDientes[32].diente.url_presente : arrayDientes[32].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[32].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(32)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  33
                </h6>
                <img
                  ref="33"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[33].presencialidad == 'presente' ? arrayDientes[33].diente.url_presente : arrayDientes[33].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[33].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(33)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  34
                </h6>
                <img
                  ref="34"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[34].presencialidad == 'presente' ? arrayDientes[34].diente.url_presente : arrayDientes[34].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[34].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(34)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  35
                </h6>
                <img
                  ref="35"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[35].presencialidad == 'presente' ? arrayDientes[35].diente.url_presente : arrayDientes[35].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[35].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(35)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  36
                </h6>
                <img
                  ref="36"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[36].presencialidad == 'presente' ? arrayDientes[36].diente.url_presente : arrayDientes[36].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[36].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(36)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  37
                </h6>
                <img
                  ref="37"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[37].presencialidad == 'presente' ? arrayDientes[37].diente.url_presente : arrayDientes[37].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[37].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(37)"
                ></b-td>
              <b-td>
                <h6 class="text-center">
                  38
                </h6>
                <img
                  ref="38"
                  :src="require(`@/assets/images/odontologia/${arrayDientes[38].presencialidad == 'presente' ? arrayDientes[38].diente.url_presente : arrayDientes[38].diente.url_ausente}`)"
                  height="50px"
                  alt="odontograma"
                  class="btn m-0 p-0 "
                  :class="arrayDientes[38].array_estados"
                  :style="{ cursor: cursorTipo }"
                  @click="abrirModal(38)"
                ></b-td>
            </b-tr>
            <b-tr>
              <b-td />
              <b-td />
              <b-td />
              <b-td><b-form-input
                v-model="arrayDientes[85].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="85"
                @update="updateInput(85)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[84].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="84"
                @update="updateInput(84)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[83].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="83"
                @update="updateInput(83)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[82].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="82"
                @update="updateInput(82)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[81].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="81"
                @update="updateInput(81)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[71].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="71"
                @update="updateInput(71)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[72].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="72"
                @update="updateInput(72)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[73].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="73"
                @update="updateInput(73)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[74].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="74"
                @update="updateInput(74)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[75].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="75"
                @update="updateInput(75)"
              /></b-td>
              <b-td />
              <b-td />
              <b-td />
            </b-tr>
            <b-tr>
              <b-td><b-form-input
                v-model="arrayDientes[48].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="48"
                @update="updateInput(48)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[47].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="47"
                @update="updateInput(47)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[46].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="46"
                @update="updateInput(46)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[45].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="45"
                @update="updateInput(45)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[44].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="44"
                @update="updateInput(44)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[43].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="43"
                @update="updateInput(43)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[42].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="42"
                @update="updateInput(42)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[41].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="41"
                @update="updateInput(41)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[31].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="31"
                @update="updateInput(31)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[32].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="32"
                @update="updateInput(32)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[33].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="33"
                @update="updateInput(33)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[34].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="34"
                @update="updateInput(34)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[35].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="35"
                @update="updateInput(35)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[36].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="36"
                @update="updateInput(36)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[37].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="37"
                @update="updateInput(37)"
              /></b-td>
              <b-td><b-form-input
                v-model="arrayDientes[38].abrev_tratamiento"
                type="text"
                class="m-0 p-0 text-center"
                placeholder="38"
                @update="updateInput(38)"
              /></b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
      <diente-modal
        :diente-odontograma-id="dienteOdontogramaId"
        :paciente="paciente"
        @actualizarOdontograma="actualizarOdontograma"
        @actualizarEstadosOdontogramaMostrado="actualizarEstadosOdontogramaMostrado"
      />
    </div>
    <div v-else>
      <b-alert
        variant="warning"
        show
      >
        <h4 class="alert-heading">
          {{ message }}
        </h4>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  BAlert, BRow, BCol, BFormInput, BTableSimple, BTbody, BTr, BTd, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, onMounted } from '@vue/composition-api'
import storeOdontograma from '@/views/salud/odontologia/odontograma/odontogramaStoreModule'
import DienteModal from './dienteModal.vue'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BCard,
    BFormCheckbox,
    // externos
    DienteModal,
  },
  props: {
    idPaciente: {
      type: Number,
      default: null,
    },
    paciente: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tacharDiente: false,
      inputText: [],
      selected: { title: 'Presencial' },
      option: [{ title: 'Presencial' }, { title: 'Llamada telefónica' }, { title: 'Videollamada' }, { title: 'WhatsApp' }],
      dateNtim: null,
      arrayDientes: [],
      //      dienteSelect: null,
      dienteOdontogramaId: null,
      result: false,
      message: '',
      cursorTipo: 'pointer',
      checked: false,
      pacientesPendientes: [
        {
          dni: '75253684', paciente: 'Federico Villegas Malpartida', tipoPaciente: 'ALUMNO', fechaAtencion: '10/08/2021',
        },
        {
          dni: '78451635', paciente: 'Carmen del Pilar Vega Alvarez', tipoPaciente: 'ALUMNO', fechaAtencion: '10/08/2021',
        },
        {
          dni: '78451245', paciente: 'Julia Ramirez Davila', tipoPaciente: 'DOCENTE', fechaAtencion: '10/08/2021',
        },
      ],

      // estilos
      porTratar: 'porTratar',
      tratado: 'tratado',
      enTratamiento: 'enTratamiento',
    }
  },
  created() {
    this.$parent.$on('nuevoOdontograma', this.generarNuevoOdontograma)
    this.$parent.$on('cargarOdontograma', this.cargarOdontograma)
  },
  mounted() {
    store.dispatch('odontogramaModule/fetchOdontograma', { id: this.idPaciente })
      .then(response => {
        console.log('FETCH ODONTOGRAMA:', response)
        this.cargarOdontograma(response.data)
        this.result = true
        this.message = ''
      })
      .catch(error => {
        console.log(error)
        this.result = false
        this.message = 'No se encontro odontograma para el paciente'
      })
  },
  methods: {
    abrirModal(dienteSelect) {
      const idDienteOdontograma = this.arrayDientes[dienteSelect].id
      this.dienteOdontogramaId = idDienteOdontograma

      if (this.tacharDiente) { // PROCESO DE TACHAR DIENTE
        console.log(`diente ${dienteSelect} tachado`)
        store.dispatch('odontogramaModule/updatePresencialidadDienteOdontograma', { idDienteOdontograma })
          .then(response => {
            console.log('respuestadiente TACHAR: ', response)

            this.arrayDientes[dienteSelect].presencialidad = response.data.message
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.$emit('cargarDatosDienteSelect', this.arrayDientes[dienteSelect])
        // this.$emit('cargarEstados', this.dienteOdontogramaId)
        // this.dienteSelect = dienteSelect
        this.$bvModal.show('dienteModal')
        // console.log('ref antes= ', this.$refs[dienteSelect].classList.value)
        // this.$refs[dienteSelect].classList.value = 'btn m-0 p-0 porTratar'
        // this.inputText[`text${dienteSelect}`] = 'BBB'
      // this.$refs[`text${dienteSelect}`].value = 'AAA'
      }
    },
    generarNuevoOdontograma(idPaci) {
      console.log('nuevo odontograma generado: PACIENTE ->', idPaci)
      store.dispatch('odontogramaModule/addOdontograma', idPaci)
        .then(response => {
          console.log('addOdontograma: ', response)
          this.cargarOdontograma(response.data)
          this.result = true
          this.message = ''
        })
        .catch(error => {
          console.log(error)
        })
    },
    cargarOdontograma(data) {
      console.log('odontograma cargado: ', data)
      const nuevoArray = []
      data.forEach(value => {
        nuevoArray[value.diente.codigo] = value
      })
      this.arrayDientes = nuevoArray
      console.log('nuevo array: ', nuevoArray)

      // seteamos odontogramaId del store
      store.state.odontogramaModule.odontogramaId = data[0].odontograma_id
    },
    ftacharDiente() {
      if (this.tacharDiente === false) {
        this.tacharDiente = true
        this.cursorTipo = 'cell'
      } else {
        this.tacharDiente = false
        this.cursorTipo = 'pointer'
      }

      // cambiar diseño del cursor
      /* const cursor = document.getElementById('cursorMouse')
      document.addEventListener('mousemove', e => {
        cursor.setAttribute('style', `top:${e.pageY - 735}px; left: ${e.pageX - 120}px; width:40px; border-radius:50px; height:40px; border:3px solid red; position: absolute; z-index: 9999;`)
      }) */
    },

    updateInput(inputNumber) {
      const data = {
        idDiente: this.arrayDientes[inputNumber].id,
        valor: this.arrayDientes[inputNumber].abrev_tratamiento,
      }
      store.dispatch('odontogramaModule/updateAbrevTratamiento', { data })
        .then(response => {
          console.log('response updateAbrevTratamiento: ', response)
          this.arrayDientes[inputNumber].abrev_tratamiento = response.data.valor
        })
        .catch(error => {
          console.log(error)
        })
    },

    actualizarOdontograma() {
      store.dispatch('odontogramaModule/fetchOdontograma', { id: this.idPaciente })
        .then(response => {
          console.log(response)
          this.cargarOdontograma(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    actualizarEstadosOdontogramaMostrado(diente) {
      this.arrayDientes[diente.codigoDiente].estados = diente.estadosDiente
    },
  },
  setup() {
    const ODONTOGRAMA_STORE_MODULE_NAME = 'odontogramaModule'

    // Register module
    if (!store.hasModule(ODONTOGRAMA_STORE_MODULE_NAME)) store.registerModule(ODONTOGRAMA_STORE_MODULE_NAME, storeOdontograma)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ODONTOGRAMA_STORE_MODULE_NAME)) store.unregisterModule(ODONTOGRAMA_STORE_MODULE_NAME)
    })

    onMounted(() => {

    })

    return {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
td img {
  transform: scale(1);
  transition: 0.5s;
}
td img:hover {
  transform: scale(0.9);
  transition: 0.5s;

}
.porTratar {
        border-bottom: solid 4px #ff0000,
      }
.tratado {
        border-top: solid 4px #0101DF,
      }
.enTratamiento {
        border-left: solid 4px #108002,
      }
.cursor2 {
width: 100px;
height: 100px;
border: 10px solid #c90303;
border-radius: 50px;
position: absolute;
background: red;
}

</style>
