import axios from '@axios'

export default {
  namespaced: true,
  state: {
    historia_id: null,
    usuario_id: null,
  },
  getters: {},
  mutations: {
    setHistoriaId(state, id) {
      state.historia_id = id
    },
    setUsuarioId(state, id) {
      state.usuario_id = id
    },
  },
  actions: {
    fetchHistorias(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/historia-clinica', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addHistoriaClinica(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('/historia-clinica', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateHistoriaClinica(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/historia-clinica/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Atenciones
    fetchAtenciones(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/historia-clinica/atenciones/${ctx.state.historia_id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchServicios() {
      return new Promise((resolve, reject) => {
        axios
          .get('/salud/tipos/servicios')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Examenes
    fetchExamenes(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/historia-clinica/examenes/${ctx.state.usuario_id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
