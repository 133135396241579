<template>
  <b-modal
    id="modal_examenes_medicos"
    :visible="shallShowExamenesModal"
    size="lg"
    title="Adjuntar documentos (exámenes médicos, recetas y/o otros.) "
    title-class="text-white"
    header-bg-variant="primary"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    cancel-title="Cerrar"
    @change="(val) => $emit('update:shall-show-examenes-modal', val)"
  >
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="FileTextIcon"
            size="18"
          />
          <span class="align-middle ml-50">Cargar documento</span>
        </b-card-title>
      </b-card-header>
      <b-card-body>

        <b-form
          enctype="multipart/form-data"
          @submit="formSubmit"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Título:"
                label-for="input-1"
              >
                <b-form-input
                  v-model="form.titulo"
                  placeholder="Nombre del examen clínico"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="9">
              <b-form-file
                id="myFile"
                :browse-text="'Seleccionar archivo'"
                :placeholder="'No se eligió archivo'"
                @change="onChange"
              >
                <template
                  slot="file-name"
                  slot-scope="{ names }"
                >
                  <b-badge variant="primary">
                    {{ names[0] }}
                  </b-badge>
                  <b-badge
                    v-if="names.length > 1"
                    variant="dark"
                    class="ml-1"
                  >
                    + {{ names.length - 1 }} More files
                  </b-badge>
                </template>
              </b-form-file>
            </b-col>
            <b-col md="3">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                <feather-icon
                  icon="SaveIcon"
                  size="15"
                  class="mr-1"
                />Guardar
              </b-button>
            </b-col>

          </b-row>
        </b-form>
      </b-card-body>
    </b-card>

    <!-- EXAMENES TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Lista de documentos cargados </span>
        </b-card-title>
      </b-card-header>

      <b-table
        striped
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
        head-variant="dark"
      >
        <template #cell(actions)="row">
          <b-button
            variant="link"
            class="text-decoration-none"
            :href="row.item.url"
            :target="'_blank'"
          >
            <feather-icon
              :id="`invoice-row-5-preview-icon`"
              icon="DownloadIcon"
              size="16"
            />
          </b-button>
          <b-button
            variant="link"
            class="text-decoration-none"
            @click="row.toggleDetails"
          >
            <feather-icon
              v-if="row.detailsShowing"
              :id="`invoice-row-5-preview-icon`"
              icon="EyeIcon"
              size="16"
            />
            <feather-icon
              v-else
              :id="`invoice-row-5-preview-icon`"
              icon="EyeOffIcon"
              size="16"
            />
          </b-button>
          <b-button
            variant="link"
            class="text-decoration-none text-danger"
            @click="eliminarExamen(row.item, row.index)"
          >
            <feather-icon
              :id="`invoice-row-5-preview-icon`"
              icon="Trash2Icon"
              size="16"
            />
          </b-button>
        </template>

        <template #row-details="row">
          <b-card>
            <ul>
              <li
                v-for="(value, key) in row.item"
                :key="key"
              >
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>
    </b-card>
  </b-modal>

</template>
<script>
import {
BBadge,
BButton,
BCard,
BCardBody,
BCardHeader, BCardTitle,
BCol,
BForm,
BFormFile,
BFormGroup,
BFormInput,
BRow,
BTable,
} from 'bootstrap-vue'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BRow, BCol, BFormGroup, BTable, BButton, BFormFile, BBadge, BForm, BCardHeader, BCardTitle, BCardBody, BFormInput,
  },
  model: {
    prop: 'shallShowExamenesModal',
    event: 'update:shall-show-examenes-modal',
  },
  props: {
    shallShowExamenesModal: {
      type: Boolean,
      required: true,
    },
    editedItem: {
      type: Object,
      default() {
        return { }
      },
    },
  },
  data() {
    return {
      paciente_id: null,
      servicio_id: null,
      atencion_id: null,
      form: {
        titulo: null,
        paciente_id: null,
        servicio_id: null,
        atencion_id: null,
        descripcion: null,
        servidor_publico_id: null,
      },
      blankForm: {
        titulo: null,
        // paciente_id: null,
        // servicio_id: null,
        // atencion_id: null,
        descripcion: null,
        // servidor_publico_id: null,
      },
      anexos: [],
      showModal: true,
      fields: [
        { key: 'titulo', sortable: true, sortDirection: 'desc' },
        { key: 'actions', label: 'Acción' },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Show a lot' }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
    }
  },
  watch: {
    editedItem(newValue, oldValue) {
      console.log(newValue, oldValue)

      this.paciente_id = newValue.paciente_id
      this.servicio_id = newValue.servicio_id
      this.atencion_id = newValue.id

      // this.form.paciente_id = newValue.paciente_id
      // this.form.servicio_id = newValue.servicio_id
      // this.form.atencion_id = newValue.id
      this.$http.get('examenes', { params: { paciente_id: this.paciente_id, servicio_id: this.servicio_id } }).then(response => {
        this.items = response.data.data
      })
    },
  },
  created() {
    // this.$http.get('examenes', { params: { paciente_id: this.form.paciente_id, servicio_id: this.form.servicio_id } }).then(response => {
    //   this.items = response.data.data
    // })
  },
  beforeUpdate() {
    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < this.listaAnexoTipos.length; i++) {
    //   // eslint-disable-next-line no-plusplus
    //   for (let j = 0; j < this.anexos.length; j++) {
    //     if (this.listaAnexoTipos[i].id === this.anexos[j].tipo_id) {
    //       this.listaAnexoTipos.splice(this.listaAnexoTipos.indexOf(this.listaAnexoTipos[i]), 1)
    //     }
    //   }
    // }
  },
  methods: {
    eliminarExamen(item, index) {
      this.$swal({
        title: '¿Estas seguro?',
        text: '¡No podrás revertir esto!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, borrar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`examenes/${item.id}`)
            .then(response => {
              console.log(response)
              this.items.splice(index, 1)
              this.toastSuccess('Tu archivo ha sido eliminado.')
              this.$swal({
                icon: 'success',
                title: 'Eliminado!',
                text: 'Tu archivo ha sido eliminado.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(() => this.toastError())
        }
      })
    },
    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.file = e.target.files[0]
    },
    formSubmit(e) {
      e.preventDefault()

      const data = new FormData()
      data.append('file', this.file)
      data.append('paciente_id', this.form.paciente_id = this.paciente_id)
      data.append('servicio_id', this.form.servicio_id = this.servicio_id)
      data.append('atencion_id', this.form.atencion_id = this.atencion_id)
      data.append('titulo', this.form.titulo)
      data.append('descripcion', this.form.descripcion)
      // Esto debe guardarse desde el backend
      data.append('servidor_publico_id', 1)

      this.$http.post('/examenes', data)
        .then(response => {
          // this.listaAnexoTipos.splice(this.listaAnexoTipos.indexOf(this.selectAnexoTipo), 1)
          document.getElementById('myFile').value = ''
          this.file = null
          this.items.push(response.data)
          this.form = { ...this.blankForm }
          this.toastSuccess('Archivo cargado exitosamente')
        })
        .catch(error => {
          console.log(error)
          this.toastError('Falló la carga')
        })
    },
  },
}
</script>
