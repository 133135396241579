<template>
  <b-modal
    id="modal_listar_odontograma"
    ok-only
    size="md"
    class="p-5"
    title="Odontogramas paciente"
  >
    <b-table
      head-variant="dark"
      class="mt-1"
      :items="historialOdontogramas"
      :fields="fieldsOdontogramas"
      :busy.sync="isBusy"
      size="sm"
      sticky-header="250px"
      small
      :empty-text="'No hay registros para mostrar'"
    >
      <template #cell(index)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(uso)="row">
        <b-badge
          pill
          :variant="`light-${row.item.id == odontogramaId ? 'success' : 'danger'}`"
          class="text-capitalize"
        >
          {{ row.item.id == odontogramaId ? 'en uso' : 'espera' }}
        </b-badge>

      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="outline-success"
          :disabled="row.item.id == odontogramaId ? true : false"
          @click="montarOdontograma(row.item.id)"
        >
          usar
          <feather-icon
            icon=""
            size="12"
          />
        </b-button>
      </template>
    </b-table>
  </b-modal>
</template>

<script>

// import store from '@/store'
import {
  BTable, BButton, BModal, BBadge,

} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BButton,
    BModal,
    BBadge,
  },
  props: {
    idPaciente: {
      type: Number,
      default: null,
    },

  },
  data() {
    return {
      isBusy: false,
      historialOdontogramas: [],
      fieldsOdontogramas: [
        {
          key: 'index', label: 'N°',
        },
        {
          key: 'created_at', label: 'Fecha Creación', sortable: true,
        },
        {
          key: 'odontologo', label: 'odontologo', class: 'text-left',
        },
        {
          key: 'uso', label: 'uso', class: 'text-center',
        },
        { key: 'actions', label: 'acción' },
      ],
    }
  },
  computed: {
    ...mapState('odontogramaModule', ['odontogramaId']),
  },
  created() {
    this.$parent.$on('listarOdontogramas', this.listarOdontogramas)
  },
  methods: {
    listarOdontogramas() {
      this.$http.get(`/servicio-salud/odontologia/paciente/${this.idPaciente}/odontograma`).then(res => {
        this.historialOdontogramas = res.data
        console.log('HISTOROIAL PACIENTE: ', res)
        console.log('OdontogramaID :', this.odontogramaId)
      })
    },
    montarOdontograma(idOdontograma) {
      this.$http.get(`/servicio-salud/odontologia/paciente/${this.idPaciente}/odontograma/${idOdontograma}`).then(res => {
        console.log('odontogramaaCArgar: ', res.data)

        this.$emit('cargarOdontogramaSeleccionado', res.data)
        this.$bvModal.hide('modal_listar_odontograma')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '¡Éxito!',
            icon: 'BellIcon',
            text: 'Odontograma cargado con exito',
            variant: 'success',
          },
        })
      })
    },
  },

}
</script>

<style>

</style>
