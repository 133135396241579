<template>
  <b-card no-body>
    <!-- <b-card-body> -->
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="mt-1"
    >
      <b-thead>
        <b-tr>
          <b-th
            colspan="4"
          >
            <div class="d-flex justify-content-between">
              <div>Diagnósticos</div>
              <div><b-button
                v-b-tooltip.hover.left="'Agregar diagnosticos'"
                variant="danger"
                size="sm"
                @click="$bvModal.show('modal_diagnostico')"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                  size="16"
                />
              </b-button></div>
            </div>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th>
            N°
          </b-th>
          <b-th>CIE - 10</b-th>
          <b-th>
            Descripción
          </b-th>
          <b-th>.</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, index) of diagnosticos"
          :key="index"
        >
          <b-td>{{ index+1 }}</b-td>
          <b-td>
            {{ item.codigo }}
          </b-td>
          <b-td>{{ item.descripcion }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <!-- </b-card-body> -->
  </b-card>

</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTd, BTh, BCard, BButton, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BTableSimple, BThead, BTbody, BTr, BTd, BTh, BCard, BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    diagnosticos: {
      type: Array,
      default() {
        return []
      },
    },
  },

}
</script>

<style lang="scss">
</style>
