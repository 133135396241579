<template>
  <div>
    <b-modal
      id="modal_formulario_vista_lectura"
      ok-only
      size="lg"
      class="p-5"
      title="RESPUESTAS FORMULARIO"
      no-close-on-esc
      hide-footer
      modal-class="modal-primary"
    >
      <FormularioRespuestasVistaLectura
        :v-if="renderizarRespFormulario"
        :id-formulario="idFormulario"
        :id-usuario="`${idUsuario}`"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
// BRow, BCard, BCol, BButton,
} from 'bootstrap-vue'
// import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import FormularioRespuestasVistaLectura from '@/views/administrador/gestion-formulario/vista-lectura/FormularioVistaLectura.vue'
// import { mapState } from 'vuex'
// import { onUnmounted } from '@vue/composition-api'
// import formularioStoreModule from '@/views/administrador/gestion-formulario/formulario/formularioStoreModule'
// import secciones from '@/views/administrador/formulario/Secciones.vue'
// import BtnEnviarFormulario from './BtnEnviarFormulario.vue'

export default {
  components: {
    BModal,
    FormularioRespuestasVistaLectura,
  },
  props: {
    idUsuario: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      idFormulario: 0,
      renderizarRespFormulario: false,
    }
  },
  computed: {
    // ...mapState('formularioModule', ['respuestaCondicional', 'estadoFormulario']),

  },
  created() {
    this.$parent.$on('abrirModalLecturaFichaClinica', this.abrirModalLecturaFichaClinica)
    this.$http.get('/formulario/FICHA-CLINICA/buscar')
      .then(res => {
        this.idFormulario = res.data.id
      })
  },
  methods: {
    abrirModalLecturaFichaClinica() {
      // this.cargarRespuesta()
      this.renderizarRespFormulario = !this.renderizarRespFormulario
      this.$bvModal.show('modal_formulario_vista_lectura')
    },
    // cargarRespuesta() {
    //   this.$http.get(`/recursos/formulario/${this.idFormulario}/respuesta?usuario_id=${500}`).then(res => {
    //     console.log('DATA: ', res)
    //     this.formulario = res.data
    //   })
    // },

  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  </style>
<style scoped>
 .wizard-icon-container{
   background-color: red;
 }
</style>
