<template>
  <section>
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ errorMessage }}
      </h4>
      <div class="alert-body">
        Ninguna atención encontrada con esta identificación. Verifique la
        <b-link class="alert-link" :to="{ name: 'salud-atencion-list' }">
          Lista de atenciones
        </b-link>
        para otras atenciones.
      </div>
    </b-alert>

    <template v-if="userData">
      <ModalFormularioVistaLectura :id-usuario="userData.paciente_id" />
      <b-card no-body class="pb-0">
        <b-card-header class="pb-0 mb-0">
          <b-card-title>
            FORMATO ÚNICO DE ATENCIÓN
            <span class="text-danger"
              >FUA N° - {{ userData.show_numero_atencion }}</span
            >
            -
            <b-badge
              :variant="userData.estado === 'completado' ? 'danger' : 'success'"
              class="text-capitalize"
            >
              {{ userData.estado }}
            </b-badge>
          </b-card-title>
          <b-dropdown
            variant="transparent"
            class="chart-dropdown"
            right
            no-caret
            size="sm"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-b-modal.atenciones-modal>
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Ver Historia Clinica</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('abrirModalLecturaFichaClinica')">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Ver Ficha Clinica</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="userData.servicio.nombre === 'Odontología'"
              @click="generarNuevoOdontograma(idPaciente)"
            >
              <feather-icon icon="PlusCircleIcon" />
              <span class="align-middle ml-50">Odontograma</span>
            </b-dropdown-item>

            <b-dropdown-item @click="$bvModal.show('modal_receta')">
              <feather-icon icon="PlusCircleIcon" />
              <span class="align-middle ml-50">Receta</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item @click="$bvModal.show('modal_interconsulta')">
              <feather-icon icon="PlusCircleIcon" />
              <span class="align-middle ml-50">Inter consulta</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </b-card-header>
        <b-row class="match-height">
          <b-col lg="4" md="6" sm="12" cols="4">
            <!-- datos del paciente -->
            <b-card no-body>
              <b-card-header class="d-flex justify-content-center pt-50 pb-50">
                <b-card-title>
                  <b-badge variant="light-primary">
                    PACIENTE
                  </b-badge>
                </b-card-title>
              </b-card-header>

              <b-card-body class="pb-0">
                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize">dni:</span>
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.numero_documento }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >apellidos:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.apellidos }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >nombres:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.nombres }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >fecha nac.</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.fecha_nacimiento }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >edad:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.edad }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >sexo:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.sexo_nombre }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >etnia</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.etnia }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >celular</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.celular }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >direccion</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.persona.direccion }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="4" md="6" sm="12" cols="4">
            <b-card no-body class="">
              <b-card-header class=" d-flex justify-content-center pt-50 pb-50">
                <b-card-title>
                  <b-badge variant="light-info">
                    DATOS DE LA ATENCIÓN
                  </b-badge>
                </b-card-title>
              </b-card-header>

              <b-card-body class="pb-0">
                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >n° atención:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.show_numero_atencion }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >n° h. clinica:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ NHistoriaClinica }}
                    <!-- {{ userData.persona.numero_documento }} -->
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >servicio:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.servicio.nombre }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >temperatura.</span
                    >
                  </b-col>
                  <b-col cols="7"> {{ userData.triaje.temperatura }} °C </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >talla:</span
                    >
                  </b-col>
                  <b-col cols="7"> {{ userData.triaje.talla }} cm </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >peso:</span
                    >
                  </b-col>
                  <b-col cols="7"> {{ userData.triaje.peso }} Kg. </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize">icm</span>
                  </b-col>
                  <b-col cols="7">
                    {{ userData.triaje.icm }}
                  </b-col>
                </b-row>

                <b-button
                  v-b-toggle.collapse-1
                  variant="success"
                  size="sm"
                  block
                >
                  Mostrar Observaciones
                </b-button>
                <b-collapse id="collapse-1" class="mt-2">
                  <div class="border rounded p-1 border-success">
                    <span>{{ userData.triaje.observaciones }}</span>
                  </div>
                </b-collapse>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col lg="4" md="6" sm="12">
            <b-card no-body>
              <b-card-header class="d-flex justify-content-center pt-50 pb-50">
                <b-card-title>
                  <b-badge variant="light-warning">
                    RESPONSABLE
                  </b-badge>
                </b-card-title>
              </b-card-header>

              <b-card-body class="pb-0">
                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize">dni:</span>
                  </b-col>
                  <!-- <pre>{{ userData.usuario_dibu }}</pre> -->
                  <b-col cols="7">
                    {{ userData.usuario_dibu.dni }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >apellidos:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.usuario_dibu.apellidos }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >nombres:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.usuario_dibu.nombres }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >especialidad:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.usuario_dibu.especialidad }}
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="5">
                    <span class="font-weight-bolder text-capitalize"
                      >colegiatura:</span
                    >
                  </b-col>
                  <b-col cols="7">
                    {{ userData.usuario_dibu.cod_colegiatura }}
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-row class="match-height">
        <b-col v-if="userData.servicio.nombre === 'Odontología'" cols="12">
          <b-card class="text-center">
            <b-row>
              <b-list-group horizontal="md">
                <b-list-group-item
                  style="border-radius:0px;border: none; border-bottom: 3px solid green"
                >
                  <strong class="text-primary">{{ odontogramaNombre }}</strong>
                </b-list-group-item>
                <b-list-group-item
                  style="border: none; "
                  class="btn"
                  @click="showModalListarOdontogramas"
                >
                  ver odontogramas paciente
                </b-list-group-item>
              </b-list-group>
            </b-row>
            <br />
            <user-odontograma
              :id-paciente="idPaciente"
              :paciente="
                userData.persona.apellido_paterno +
                  ' ' +
                  userData.persona.apellido_materno +
                  ' ' +
                  userData.persona.nombres
              "
            />

            <!--             <b-tabs>
                <b-tab
                  :title="odontogramaNombre"
                  active
                >
                  <b-card-text />
                </b-tab>
                <b-tab
                  title="Historia Odontograma"
                >
                  <b-card-text>
                    Historial de odontogramas
                    <historial-odontograma :id-paciente="idPaciente" />
                  </b-card-text>
                </b-tab>
              </b-tabs> -->
          </b-card>
        </b-col>

        <b-col lg="6">
          <atencion-diagnostico :diagnosticos="userData.diagnosticos" />
          <!-- <pre>{{ userData.diagnosticos }}</pre> -->
        </b-col>

        <b-col lg="6">
          <atencion-procedimiento :procedimientos="userData.procedimientos" />
        </b-col>

        <b-col lg="12">
          <b-card no-body>
            <div class="m-2">
              <b-row>
                <b-col md="6" lg="2" sm="12">
                  <b-form-group label="Modalidad de atención">
                    <b-form-select
                      v-model="userData.modalidad_atencion"
                      class="text-capitalize"
                      :options="optionsModalidad"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6" lg="2" sm="12">
                  <b-form-group label="Tipo de atención">
                    <b-form-select
                      v-model="userData.tipo"
                      class="text-capitalize"
                      :options="optionsTipoAtencion"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="2" md="3" sm="12">
                  <b-form-group label="¿Vas a derivar?">
                    <b-form-checkbox
                      v-model="userData.derivado"
                      value="1"
                      switch
                      unchecked-value="0"
                    >
                      Sí
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col lg="2" md="3" sm="12">
                  <b-form-group label="Tipo de derivacón">
                    <b-form-select
                      v-model="userData.tipo_derivacion"
                      :disabled="userData.derivado === '1' ? false : true"
                      class="text-capitalize"
                      :options="optionsTipoDerivacion"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="6" sm="12">
                  <b-form-group label="Lugar de derivación">
                    <div v-if="userData.tipo_derivacion == 'interna'">
                      <v-select
                        v-model="userData.derivado_servicio_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="nombre"
                        :options="optionsTipos"
                        :reduce="(val) => val.id"
                        placeholder="Seleccionar"
                        :disabled="userData.derivado === '1' ? false : true"
                      />
                    </div>
                    <b-form-input
                      v-else
                      v-model="userData.lugar_derivacion"
                      :disabled="userData.derivado === '1' ? false : true"
                      placeholder="Lugar de derivación"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col lg="8">
          <b-card>
            <b-form-group label="Observaciones">
              <b-form-textarea
                v-model="userData.observacion"
                placeholder="Observaciones"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col lg="4">
          <b-card>
            <!-- <div class="d-flex justify-content-around align-items-center"> -->
            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              block
              size="sm"
              @click="showDerivarModal"
            >
              Realizar interconsulta
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              block
              size="sm"
              @click="$bvModal.show('modal_receta')"
            >
              Recetar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              block
              size="sm"
              @click="showExamenesModal"
            >
              Adjuntar documentos
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="danger"
              block
              size="sm"
              @click="finalizarAtencion"
            >
              Finalizar atención
            </b-button>
            <!-- </div> -->
          </b-card>
        </b-col>
        <user-view-user-receta
          :paciente="
            userData.persona.apellido_paterno +
              ' ' +
              userData.persona.apellido_materno +
              ' ' +
              userData.persona.nombres
          "
          :pac-documento-identidad="userData.persona.numero_documento"
          :id-paciente="idPaciente"
          :receta="userData.receta"
          @update-receta-item="(val) => (userData.receta = val)"
        />
      </b-row>
    </template>

    <!-- <atencion-modal-derivar
      v-model="shallShowEmailComposeModal"
      :edited-item="editedItem"
      @actualizar-servicios="serviciosActualizados"
    /> -->
    <atencion-modal-diagnostico
      @actualizar-diagnosticos-atencion="(val) => (userData.diagnosticos = val)"
    />
    <atencion-modal-procedimiento
      @actualizar-procedimientos-atencion="
        (val) => (userData.procedimientos = val)
      "
    />
    <modal-listar-odontograma
      :id-paciente="idPaciente"
      @cargarOdontogramaSeleccionado="cargarOdontogramaSeleccionado"
    />

    <atencion-modal-examenes
      v-model="shallShowExamenesModal"
      :edited-item="editedItem"
    />
    <AtencionModalList :historia="historia" />
  </section>
</template>

<script>
import { onUnmounted, ref } from "@vue/composition-api";

import router from "@/router";
import store from "@/store";
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BLink,
  BListGroup,
  BListGroupItem,
  BRow,
  VBToggle,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import { parse, differenceInHours } from 'date-fns'
import UserViewUserReceta from "@/views/salud/atencion/atencion-receta/AtencionReceta.vue";
import AtencionModalList from "@/views/salud/historia/components/AtencionModalList.vue";
import ModalFormularioVistaLectura from "@/views/salud/triaje/triajes-list/ModalFormularioVistaLectura.vue";
import vSelect from "vue-select";
import userStoreModule from "../userStoreModule";
import AtencionDiagnostico from "./AtencionDiagnostico.vue";
import AtencionModalDiagnostico from "./AtencionModalDiagnostico.vue";
import AtencionModalProcedimiento from "./AtencionModalProcedimiento.vue";
import AtencionProcedimiento from "./AtencionProcedimiento.vue";
import UserOdontograma from "./userOdontograma.vue";
// import AtencionModalDerivar from './AtencionModalDerivar.vue'
import axios from "@axios";
import AtencionModalExamenes from "./AtencionModalExamenes.vue";
import ModalListarOdontograma from "./ModalListarOdontograma.vue";

export default {
  components: {
    BCollapse,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormTextarea,
    BBadge,
    BListGroup,
    BListGroupItem,
    BButton,
    BCardTitle,
    BFormGroup,
    BFormInput,
    AtencionModalList,
    ModalFormularioVistaLectura,
    /* BTabs,
    BCardText,
    BTab, */

    // externos
    // vSelect,
    // BAlert,
    // BLink,
    UserViewUserReceta,
    UserOdontograma,
    AtencionDiagnostico,
    AtencionProcedimiento,
    AtencionModalDiagnostico,
    AtencionModalProcedimiento,
    // AtencionModalDerivar,
    ModalListarOdontograma,
    AtencionModalExamenes,
    vSelect,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  created() {
    axios
      .get("/salud/tipos/servicios")
      .then(({ data }) => (this.optionsTipos = data.data))
      .catch(() => (this.optionsTipos = []));
  },
  data() {
    return {
      selectedModalidad: "presencial",
      optionsModalidad: [
        "presencial",
        "llamada telefónica",
        "videollamada",
        "whatsApp",
      ],
      selectedTipoAtencion: "consulta regular",
      optionsTipoAtencion: ["consulta regular", "emergencia"],
      selectedTipoDerivacion: null,
      optionsTipoDerivacion: ["externa", "interna"],
      odontogramaNombre: "Odontograma X",
      selected: { title: "Presencial" },
      option: [
        { title: "Presencial" },
        { title: "Llamada telefónica" },
        { title: "Videollamada" },
        { title: "WhatsApp" },
      ],
      dateNtim: null,
      historia: {
        id: "info-modal",
        title: "",
        content: "",
      },
      optionsTipos: [],
    };
  },
  methods: {
    generarNuevoOdontograma(idPaciente) {
      // console.log('mi estado', this.userData.estado)
      if (this.userData.estado === "completado") {
        this.toastError(
          "No se puede realizar cambios, de una atención terminada"
        );
        return;
      }
      this.$swal({
        title: "¿Estas seguro?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, crear nuevo odontograma!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // console.log('nuevo dodontohrama en proceso!!', idPaciente)
          this.$emit("nuevoOdontograma", idPaciente);
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelado",
            text: "Tu odontograma actual está seguro :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    showModalListarOdontogramas() {
      this.$emit("listarOdontogramas");
      this.$bvModal.show("modal_listar_odontograma");
    },
    cargarOdontogramaSeleccionado(dataOdontograma) {
      this.$emit("cargarOdontograma", dataOdontograma);
    },
    finalizarAtencion() {
      this.$swal({
        title: "¿Estas seguro?",
        text: "Una vez finalizada la atención ya no podrá realizar cambios.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, guardar atención!",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          store
            .dispatch("app-atencion/finalizarAtencion", this.userData)
            .then(() => {
              this.toastSuccess("Finalización exitosa!");
              this.$router.push({ name: "salud-atencion-list" });
            })
            .catch((error) => {
              this.toastError(`${error.response.data.error}`);
            });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Cancelado",
            text: "Tu atención aún no ha sido registrado :)",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
  setup(_, { emit }) {
    const userData = ref(null);
    const idPaciente = ref(null);
    const editedItem = ref({});
    const errorMessage = ref(null);
    const shallShowEmailComposeModal = ref(false);
    const shallShowExamenesModal = ref(false);
    const NHistoriaClinica = ref(null);
    const historia = ref({});
    const ATENCION_APP_STORE_MODULE_NAME = "app-atencion";

    // Register module
    if (!store.hasModule(ATENCION_APP_STORE_MODULE_NAME))
      store.registerModule(ATENCION_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ATENCION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ATENCION_APP_STORE_MODULE_NAME);
    });

    const showDerivarModal = () => {
      editedItem.value = { ...userData.value };
      shallShowEmailComposeModal.value = true;
      emit("update:shall-show-email-compose-modal", true);
    };

    const showExamenesModal = () => {
      editedItem.value = { ...userData.value };
      shallShowExamenesModal.value = true;
      emit("update:shall-show-examenes-modal", true);
    };

    store
      .dispatch("app-atencion/fetchAtencion", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        idPaciente.value = response.data.paciente_id;
        const { usuario, ...rest } = response.data;
        const { perfil } = usuario;
        const { persona } = perfil;
        userData.value = { ...rest, persona };
        if (response.data.usuario_dibu === null) {
          userData.value.usuario_dibu = JSON.parse(
            localStorage.getItem("userData")
          );
        }
        NHistoriaClinica.value = rest.historia_clinica.numero_historia_clinica;
        historia.value = rest.historia_clinica;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          errorMessage.value = "Error al obtener los datos de la atencion";
          userData.value = undefined;
        } else if (error.response.status === 406) {
          errorMessage.value = error.response.data.error;
          userData.value = undefined;
        }
      });

    const serviciosActualizados = (item) => {
      userData.value.servicios = item;
    };

    return {
      userData,
      idPaciente,
      errorMessage,
      showDerivarModal,
      shallShowEmailComposeModal,
      editedItem,
      serviciosActualizados,
      shallShowExamenesModal,
      showExamenesModal,
      NHistoriaClinica,
      historia,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
