import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addAtencionDiente(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/servicio-salud/odontologia/diente/${params.diente_odontograma_id}/atencion`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAtencionDiente(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/servicio-salud/odontologia/diente/${params.diente_odontograma_id}/atencion/${params.atencionDiente_id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAtencionDiente(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-salud/odontologia/diente/${params.diente_odontograma_id}/atencion/${params.atencionDiente_id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAtencionesDiente(ctx, dienteId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-salud/odontologia/diente/${dienteId}/atencion`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAtencionDiente(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/servicio-salud/odontologia/diente/${params.dienteId}/atencion/${params.atencionDienteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // peculiaridades
    getEstadosDiente() {
      return new Promise((resolve, reject) => {
        axios
          .get('servicio-salud/odontologia/odontograma/get-estados-diente')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEstados(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('servicio-salud/odontologia/odontograma/update-estados-dientes', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
