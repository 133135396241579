import axios from '@axios'

export default {
  namespaced: true,
  state: {
    odontogramaId: '',
  },
  getters: {},
  mutations: {},
  actions: {
    fetchOdontograma(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`servicio-salud/odontologia/paciente/${id}/get-ultimo-odontograma`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOdontograma(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`servicio-salud/odontologia/paciente/${id}/odontograma`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePresencialidadDienteOdontograma(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/servicio-salud/odontologia/odontograma/tachar-diente', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAbrevTratamiento(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .post('servicio-salud/odontologia/odontograma/update-abrev-tratamiento', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
